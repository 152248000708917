.fade-appear,
.fade-enter {
  opacity: 0;
}

.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}

.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.owl-stage {
  transform: translate3d(-1200px, 0px, 0px);
  transition: 1.25s;
  width: 3600px;
  display: flex;
  align-items: center;
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: 1s;
  transition-property: opacity;
}