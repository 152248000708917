.error-block {
  width: 100%;
  height: 100%;
}
.error-block__title {
  margin-bottom: 30px;
  font-size: 200px;
  text-align: center;
}
.error-block__subtitle {
  padding-top: 30px;
  margin-bottom: 30px;
  font-size: 30px;
  text-align: center;
}
.error-block__text {
  max-width: 960px;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 20px;
  text-align: center;
}
.error-block__button {
  text-align: center;
}